import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [ 'burgerMenu' ]
  
  connect() {}

  toggle(event) {
      let menuItems = document.getElementById(this.burgerMenuTarget.dataset.target)
       // toggle burger
      this.burgerMenuTarget.classList.toggle('is-active')
      // toggle menue items
      menuItems.classList.toggle('is-active')
      menuItems.classList.toggle('is-burger')
  }

}
