import { Controller } from '@hotwired/stimulus'
// https://masonry.desandro.com/options
import Masonry from 'masonry-layout/dist/masonry.pkgd'
// https://github.com/heimrichhannot/masonry-imagesloaded
import ImagesLoaded from 'imagesloaded/imagesloaded.pkgd'
import ScrollReveal from 'scrollreveal/dist/scrollreveal'


export default class extends Controller {
  
  connect() {
    var $masonry = $(this.element).masonry({
      initLayout: false,
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      gutter: '.gutter',
      horizontalOrder: true,
      percentPosition: true
    })

    $(this.element).imagesLoaded( function() {
      $masonry.masonry()
      ScrollReveal().reveal('.grid-item', { duration: 800 })
    })
  }

}