import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

export function uppyInstance({ id, types, server }) {
  const uppy = new Uppy({
    id: id,
    // autoProceed: true,
    restrictions: {
      allowedFileTypes: types,
    },
  })

  uppy.use(XHRUpload, {
    // server: {
    //   host: 'localhost:3000',
    //   protocol: 'http',
    // },
    endpoint: '/upload',
    fieldName: 'file',
    // formData: true,
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    }
  })

  return uppy
}

