// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
/*eslint no-console: ['error', { allow: ['warn', 'error'] }] */

const images = require.context('../assets/images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)

// -------------------
// Import JS libraries
// -------------------
// Adds the `jquery` to the global object under the names `$` and `jQuery`
import $ from 'expose-loader?exposes=$,jQuery!jquery'
import 'jquery-ui/dist/jquery-ui'

// import './active_admin'

// Import React Stuff
import './huetten-kalender-bundle'
// import './image-upload-bundle'

// react-vis CSS - Auswertung der Hüttenbelegung
import 'react-vis/dist/style'

import '../controllers'

// Turbo Rails'
import "@hotwired/turbo-rails"
// import { Turbo } from "@hotwired/turbo-rails"
// Turbo.session.drive = false

// -------------------
// Import css files
// -------------------

// Application CSS
import '../assets/styles/harmonie-muenchen.scss'

// Lightbox 2
// https://lokeshdhakar.com/projects/lightbox2/#options
import 'lightbox2/src/css/lightbox.css'
import lightbox from 'lightbox2'

document.addEventListener("turbo:load", (event) => {
  lightbox.init()
  lightbox.option({
  })
})

document.addEventListener('turbo:load', function () {
  $('.navbar-item.has-dropdown .navbar-link').click(function (e) {
    if ($('.navbar-burger').is(':visible')) {
      $(this).parent().toggleClass('is-active')
    }
  })
  $('.navbar-link').click(function (e) {
    if ($('.navbar-burger').is(':visible')) {
      e.preventDefault()
    }
  })
  $(window).resize(function (e) {
    if (!$('.navbar-burger').is(':visible') && $('.navbar-item.has-dropdown.is-active').length) {
      $('.navbar-item.has-dropdown.is-active').removeClass('is-active')
    }
  })
})
