import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [ 'item' ]
  
  connect() {
  }

  dropDown(event) {
    // event.preventDefault()
    let burger = document.getElementsByClassName('burger')
    if (burger[0].classList.contains('is-active') ) {
      this.itemTarget.classList.toggle('is-active')
    }
  }
}
