import ReadMore from "@stimulus-components/read-more"

export default class extends ReadMore {
  connect() {
    super.connect()
    console.log("Do what you want here.")
  }

  // Function to override on toggle.
  // toggle(event) {}

  // Function to override when the text is shown.
  // show(event) {}

  // Function to override when the text is hidden.
  // hide(event) {}
}