import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [ "hero" ]

  connect() {
    this.originalDimensions = this.heroTarget.getBoundingClientRect()
    this.heroHeader = this.heroTarget
    this.onScrollRunning = true
    this.heroTarget.style.top = 0
    $('section.hero').css('top',0)
    this.resizeHeader()
  }

  onScroll(event) {
    if (!this.onScrollRunning) {
      this.onScrollRunning = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.scrollHeroHeader.bind(this));
      } else {
        setTimeout(this.scrollHeroHeader.bind(this), 66);
      }
    }
  }

  scrollHeroHeader() {
    var heroRect = this.heroTarget.getBoundingClientRect()
    var stickyOffset = -heroRect.height+152
    if (stickyOffset > 0) stickyOffset = 0
    $('section.hero').css('top',stickyOffset)

    var heroTitle = document.getElementById('vereins-titel')
    if (heroTitle) {
      var contentScrollTop = heroTitle.getBoundingClientRect().y
      if (contentScrollTop < 162) {
        document.getElementById('vereins-titel').style.opacity = 0
      }
      if (contentScrollTop >= 162) {
        document.getElementById('vereins-titel').style.opacity = 1
      }
      if (heroRect.y-stickyOffset == 0) {
        document.getElementsByClassName('hero-body')[0].classList.add('transparent-after')
      } else {
        document.getElementsByClassName('hero-body')[0].classList.remove('transparent-after')
      }
    }

    this.onScrollRunning = false
  }

  resizeHeader() {
      
    this.scrollHeroHeader()
  }
}