import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {

  connect() {
    console.log('drag connected')
    this.sortable = Sortable.create(this.element, {
      draggable: '.draggable',
      onEnd: this.end.bind(this)
    })
  } 

  end(event) {
    console.log(event)
    console.log(this.data.get("url"))
    let list = this.element.getElementsByClassName('draggable')
    let index = 1
    for (const photo of list){
      let id = photo.dataset.id
      let data = new FormData()
      data.append("position", index++)
      Rails.ajax({
        url: this.data.get("url").replace(":id", id),
        type: 'PATCH',
        data: data
      })
    }
  }
  
}