import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import { MarksmithController, ListContinuationController } from '@avo-hq/marksmith'
import ReadMore from '@stimulus-components/read-more'

const application = Application.start()
const context = require.context('.', true, /\.js$/)
application.load(definitionsFromContext(context))
// Markdown Editor
// https://github.com/avo-hq/marksmith?tab=readme-ov-file
application.register('marksmith', MarksmithController)
application.register('list-continuation', ListContinuationController)
application.register('read-more', ReadMore)

// import RemovalsController from "./removals_controller.js"
// application.register("removals", RemovalsController)