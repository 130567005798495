import { Controller } from '@hotwired/stimulus'
import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor'
import { uppyInstance } from '../libs/uppy'
import DropTarget from '@uppy/drop-target'
import Compressor from '@uppy/compressor'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import '@uppy/image-editor/dist/style.min.css'

export default class extends Controller {
  static targets = [ 'input' ]
  static values = { types: Array, server: String }

  connect() {
    this.uppy = this.createUppy()
  }

  disconnect() {
    // this.uppy.close()
  }
  
  createUppy() {
    var fileInput = this.inputTarget
    const uppy = uppyInstance({
      id: fileInput.id,
      types: this.typesValue,
      server: this.serverValue,
    })
    .use(Dashboard, {
      target: this.inputTarget.parentNode,
      inline: true,
      replaceTargetContent: true,
      showProgressDetails: true,
      // width: "100% !important",
      // height: "100%",
    })
    .use(ImageEditor, {
      target: Dashboard
    })
    .use(DropTarget, {
      target: document.body
    })
    .use(Compressor)

    document.getElementsByClassName("uppy-Root")[0].style.width = '100%'
    // document.getElementsByClassName("uppy-Root")[0].style.height = 'auto'
    document.getElementsByClassName("uppy-Dashboard-inner")[0].style.width = '100%'
    // document.getElementsByClassName("uppy-Dashboard-inner")[0].style.height = 'auto'

    uppy.on('upload-success', (file, response) => {
      // console.log('Response: ', response)
      var columnsTag
      const uploadedFileData = JSON.stringify(response.body) // Shrine returns JSON
      const uploadedFileUrl = response.body.url
      const filename = response.body.title
      
      var template = document.getElementById(fileInput.dataset.template)
      var previewList = document.getElementById(fileInput.dataset.previews)

      template = template.innerHTML.replace(/{{value}}/, uploadedFileData).replace(/{{source}}/, uploadedFileUrl).replace(/{{title}}/, filename)
      const numItems = $('.column.is-one-quarter').length
      if (numItems % 4 == 0) {
        columnsTag = document.createElement("div")
        columnsTag.classList.add('columns', 'p-0')
        previewList.appendChild(columnsTag)
        previewList.lastElementChild.insertAdjacentHTML('beforeend', template)
      } else {
        previewList.lastElementChild.insertAdjacentHTML('beforeend', template)
      }
    })

    uppy.on('complete', (result) => {
      console.log('successful files: ', result.successful)
      console.log('failed files: ', result.failed)
    })
    return uppy
  }
}
